<template>
  <div
    class="animated-background"
    @mousemove="handleMouseMove"
    @touchmove.prevent="handleTouchMove"
  >
    <div
      v-for="(particle, index) in particles"
      :key="index"
      class="particle"
      :style="{
        left: `${particle.x}px`,
        top: `${particle.y}px`,
        width: `${particle.size}px`,
        height: `${particle.size}px`,
        backgroundColor: particle.color,
      }"
    ></div>

    <!-- Portfolio Icon with Eye -->
    <div
      class="portfolio-icon"
      @click="toggleMessage"
      :class="{ 'icon-clicked': isMessageVisible }"
    >
      <div class="icon-circle">
        <div
          class="eye"
          :style="{
            transform: `translate(${eyeOffsetX}px, ${eyeOffsetY}px)`,
          }"
        >
          <div class="technology-ring"></div>
          <div class="technology-core"></div>
        </div>
      </div>
      <div
        class="message-popup"
        :class="{ 'message-visible': isMessageVisible }"
      >
        <span>JOB WANTED!</span>
        <div class="background-pulse"></div>
      </div>
    </div>

    <!-- Gradient Overlay -->
    <div class="gradient-overlay"></div>
  </div>
</template>

  
<script>
import { ref, reactive, onMounted, computed, onUnmounted } from "vue";

export default {
  name: "AnimatedBackground",
  setup() {
    const particles = ref([]);
    const mousePosition = reactive({ x: 0, y: 0 });
    const isMessageVisible = ref(false);

    // Eye position offsets based on mouse or touch movement
    const eyeOffsetX = computed(() => {
      const maxOffset = 10;
      const distance = mousePosition.x - (window.innerWidth - 80);
      return Math.min(Math.max((distance / 100) * maxOffset, -maxOffset), maxOffset);
    });

    const eyeOffsetY = computed(() => {
      const maxOffset = 10;
      const distance = mousePosition.y - 80;
      return Math.min(Math.max((distance / 100) * maxOffset, -maxOffset), maxOffset);
    });

    // Toggle message visibility when the portfolio icon is clicked
    const toggleMessage = () => {
      isMessageVisible.value = !isMessageVisible.value;
      setTimeout(() => {
        isMessageVisible.value = false;
      }, 5000);
    };

    // Particle generation logic
    const generateParticles = () => {
      return Array.from({ length: 50 }, () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 6 + 4,
        speedX: (Math.random() - 0.5) * 0.75,
        speedY: (Math.random() - 0.5) * 0.75,
        color: "rgba(130, 230, 250, 0.4)",
      }));
    };

    // Particle animation logic
    const animateParticles = () => {
      particles.value = particles.value.map((particle) => {
        let newX = particle.x + particle.speedX;
        let newY = particle.y + particle.speedY;

        if (newX < 0) newX = window.innerWidth;
        if (newX > window.innerWidth) newX = 0;
        if (newY < 0) newY = window.innerHeight;
        if (newY > window.innerHeight) newY = 0;

        return {
          ...particle,
          x: newX,
          y: newY,
        };
      });
      requestAnimationFrame(animateParticles);
    };

    // Mouse movement tracking
    const handleMouseMove = (e) => {
      mousePosition.x = e.clientX;
      mousePosition.y = e.clientY;
    };

    // Touch movement tracking
    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      mousePosition.x = touch.clientX;
      mousePosition.y = touch.clientY;
    };

    // Initialize particle system and event listeners on mount
    onMounted(() => {
      particles.value = generateParticles();
      animateParticles();

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("touchmove", handleTouchMove);
    });

    // Cleanup event listeners on unmount
    onUnmounted(() => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
    });

    return {
      particles,
      handleMouseMove,
      handleTouchMove,
      eyeOffsetX,
      eyeOffsetY,
      isMessageVisible,
      toggleMessage,
    };
  },
};
</script>

  
  <style scoped>
  .animated-background {
    position: fixed;
    inset: 0;
    overflow: hidden;
    background-color: #1e293b; /* Dark background to make particles stand out */
  }
  
  .particle {
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s linear;
  }
  
  .portfolio-icon {
    position: fixed;
    right: 20px;
    top: 70px;
    width: 60px;
    height: 60px;
    z-index: 100;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .portfolio-icon:hover {
    transform: scale(1.1);
  }
  
  .icon-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    background: linear-gradient(145deg, rgba(37, 99, 235, 0.8), rgba(59, 130, 246, 0.8));
    box-shadow: 0 0 30px rgba(37, 99, 235, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
  }
  
  .eye {
    width: 30px;
    height: 30px;
    position: relative;
    transition: transform 0.2s ease;
  }
  
  .technology-ring {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    animation: rotate 8s linear infinite;
  }
  
  .technology-core {
    position: absolute;
    width: 40%;
    height: 40%;
    left: 30%;
    top: 30%;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  }
  
  .message-popup {
    position: absolute;
    top: 70px;
    right: 0;
    transform: scale(0);
    background: rgba(37, 99, 235, 0.9);
    padding: 8px 16px;
    border-radius: 16px;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
    backdrop-filter: blur(5px);
  }
  
  .message-visible {
    transform: scale(1);
    opacity: 1;
  }
  
  .icon-clicked .icon-circle {
    transform: scale(0.95);
    box-shadow: 0 0 50px rgba(37, 99, 235, 0.5);
  }
  
  .background-pulse {
    position: absolute;
    inset: -2px;
    border-radius: 16px;
    background: linear-gradient(45deg, rgba(37, 99, 235, 0.8), rgba(59, 130, 246, 0.8));
    opacity: 0.3;
    z-index: -1;
    animation: pulse 2s ease-out infinite;
  }
  
  .gradient-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom right, transparent, rgba(0, 0, 0, 0.5));
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.3;
    }
    50% {
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0.3;
    }
  }
  </style>
  